<template>
  <div>
    <a-modal v-model="visible" :footer="null" :confirmLoading="loading" :maskClosable="false" @cancel="cancel">
      <div slot="title">查看</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" label="产品名称">
            <a-select
                    disabled
                    v-model="form.name"
                    style="width: 100%"
                    @change="handleChange"
            >
              <a-select-option
                      v-for="val in productList"
                      :key="val.id"
                      :value="val.name + '-' + val.id"
              >
                {{ val.name }} ({{ val.id }})
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="sales_area" label="销售区域">
            <a-input disabled v-model="form.sales_area" />
          </a-form-model-item>
          <!-- <a-form-model-item prop="period" label="产品保质期">
            <a-input disabled v-model="form.period" />
          </a-form-model-item> -->
          <a-form-model-item prop="commpany" label="生产企业">
            <a-textarea disabled v-model="form.commpany" />
          </a-form-model-item>
          <a-form-model-item prop="adreess" label="生产地址">
            <a-textarea disabled v-model="form.adreess" />
          </a-form-model-item>
          <a-form-model-item prop="introduce" label="产品介绍">
            <a-input disabled v-model="form.introduce" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="联系方式">
            <a-input disabled v-model="form.remark" />
          </a-form-model-item>
          <!-- <a-form-model-item prop="bar_code" label="条形码">
            <a-input disabled v-model="form.bar_code" />
          </a-form-model-item> -->
          <a-form-model-item prop="licence_no" label="许可证编号">
            <a-input disabled v-model="form.licence_no" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="ingredients" label="配料表">
            <a-textarea disabled v-model="form.ingredients" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { goodsInformationList } from '@/api/goods'
import { fromProductAdd, fromProductEdit } from "@/api/from";

export default {
  name: "FormModal",
  props: ["visible", "form", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      productList: [],
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        name: [
          { required: true, message: "请选择产品名称", trigger: "change" }
        ],
        /* product_specs: [
          { required: true, message: "请输入产品规格", trigger: "blur" }
        ],
        product_brand: [
          { required: true, message: "请输入产品品牌", trigger: "blur" }
        ], */
        sales_area: [
          { required: true, message: "请输入销售区域", trigger: "blur" }
        ],
        /* product_type: [
          { required: true, message: "请输入产品类型", trigger: "blur" }
        ],
        standards: [
          { required: true, message: "请输入执行标准", trigger: "blur" }
        ], */
        period: [
          { required: true, message: "请输入产品保质期", trigger: "blur" }
        ],
        commpany: [
          { required: true, message: "请输入生产企业", trigger: "blur" }
        ],
        adreess: [
          { required: true, message: "请输入生产地址", trigger: "blur" }
        ],
        introduce: [
          { required: true, message: "请输入产品介绍", trigger: "blur" }
        ]
      },
      loading: false,
    };
  },
  created() {
    this.productList = [];
    this.productCount = 0;
    const pageSize = 999999;
    const endIf = () => this.productList.length >= this.productCount;
    const loopLoad = (page, end) => {
      return goodsInformationList({ page, page_size: pageSize }).then(
              (res) => {
                this.productCount = res.count || this.productCount;
                this.productList.push(...res.results);
                // console.log(this.productList);
                if (!end()) {
                  return loopLoad(page + 1, end);
                }
              }
      );
    };
    loopLoad(1, endIf);
  },
  methods: {
    handleChange(val) {
      this.form.product_name = val.split('-')[0];
      this.form.product_id = val.split('-')[1];
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? fromProductEdit : fromProductAdd;
          func(this.form)
            .then(() => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      if(!this.form.id) {
        this.$refs.form.resetFields();
      }
    },
  },
};
</script>

<style scoped></style>
