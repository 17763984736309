import { render, staticRenderFns } from "./FormModal1.vue?vue&type=template&id=524b1ab4&scoped=true"
import script from "./FormModal1.vue?vue&type=script&lang=js"
export * from "./FormModal1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524b1ab4",
  null
  
)

export default component.exports